import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import getPodcasts from "../../api/action.getPodcasts";
import logError from "../../helpers/logError";
import sortPodcasts from "../../helpers/sortPodcasts";

const Podcast = () => {
  const [podcasts, setPodcasts] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const rawPodcasts = await getPodcasts();
        if (rawPodcasts.error) {
          throw rawPodcasts.error;
        }

        setPodcasts(sortPodcasts(rawPodcasts.data));
      } catch (err) {
        logError(err);
      }
    })();
  }, []);

  return (
    <section className="tf-about-section reverse section-padding" id="podcast">
      <div className="container">
        <div className="tf-about-wrap">
          <div className="standup-wrapper">
            <div className="tf-about-img reverted podcasts-wrapper">
              {podcasts.slice(0, 3).map((podcast) => {
                return (
                  <Link
                    key={podcast.id}
                    to={podcast.attributes.url}
                    target="_blank"
                  >
                    <div className="single-podcast">
                      <img
                        src={
                          process.env.REACT_APP_STRAPI_URL +
                          podcast.attributes.thumbnail.data.attributes.formats
                            .thumbnail.url
                        }
                        alt="Podcast Thumbnail"
                      />
                      <p>{podcast.attributes.description}</p>
                    </div>
                  </Link>
                );
              })}
            </div>
            <div className="tf-about-text podcast">
              <small>Podcast</small>
              <h2>
                Zanurz się w świat Najlepszego Podcastu w Polsce: Filip Puzyr i
                Piotr Splin w akcji!
              </h2>
              <p>
                Filip Puzyr jest również współautorem podcastu “Najlepszy
                Podcast w Polsce”, który prowadzi z kolegą po fachu, Piotrem
                Splinem. Spektrum poruszanych tematów jest szeroki niczym
                zainteresowania prowadzących, a wszystko to okraszone ironicznym
                poczuciem humoru. Czy rzeczywiście ten projekt zasługuje na swój
                tytuł? Pamiętaj, jest tylko jeden sposób, by się przekonać.
              </p>
              <div className="socials-line">
                <p>Subskrybuj:</p>
                <Link
                  to="https://podcasts.apple.com/pl/podcast/najlepszy-podcast-w-polsce/id1548781863?l=pl"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="alt-icon"
                  >
                    <path
                      d="M24.4853 24.4852C26.1636 22.807 27.3064 20.6688 27.7694 18.341C28.2324 16.0133 27.9948 13.6005 27.0865 11.4078C26.1783 9.21506 24.6402 7.34092 22.6668 6.02235C20.6934 4.70378 18.3734 4 16 4C13.6266 4 11.3066 4.70378 9.33318 6.02235C7.35979 7.34092 5.82172 9.21506 4.91346 11.4078C4.00521 13.6005 3.76756 16.0133 4.23057 18.341C4.69358 20.6688 5.83646 22.807 7.51467 24.4852"
                      stroke="#F5F5F5"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.688 29.333H16.312C16.9621 29.3331 17.5898 29.0958 18.0772 28.6656C18.5646 28.2355 18.8781 27.6421 18.9587 26.997L19.6253 21.6637C19.6722 21.2884 19.6388 20.9075 19.5272 20.5462C19.4156 20.1849 19.2285 19.8514 18.9782 19.5679C18.7279 19.2845 18.4201 19.0575 18.0754 18.9021C17.7307 18.7466 17.3568 18.6663 16.9787 18.6663H15.0213C14.6432 18.6663 14.2693 18.7466 13.9246 18.9021C13.5799 19.0575 13.2721 19.2845 13.0218 19.5679C12.7715 19.8514 12.5844 20.1849 12.4728 20.5462C12.3612 20.9075 12.3278 21.2884 12.3747 21.6637L13.0413 26.997C13.122 27.6421 13.4354 28.2355 13.9228 28.6656C14.4102 29.0958 15.0379 29.3331 15.688 29.333ZM13.3333 11.9997C13.3333 12.7069 13.6143 13.3852 14.1144 13.8853C14.6145 14.3854 15.2928 14.6663 16 14.6663C16.7073 14.6663 17.3855 14.3854 17.8856 13.8853C18.3857 13.3852 18.6667 12.7069 18.6667 11.9997C18.6667 11.2924 18.3857 10.6142 17.8856 10.1141C17.3855 9.61396 16.7073 9.33301 16 9.33301C15.2928 9.33301 14.6145 9.61396 14.1144 10.1141C13.6143 10.6142 13.3333 11.2924 13.3333 11.9997Z"
                      stroke="#F5F5F5"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
                <Link
                  to="https://www.youtube.com/@najlepszypodcastwpolsce2356"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <path d="M13.3333 19.9998L20.2533 15.9998L13.3333 11.9998V19.9998ZM28.7467 9.55984C28.92 10.1865 29.04 11.0265 29.12 12.0932C29.2133 13.1598 29.2533 14.0798 29.2533 14.8798L29.3333 15.9998C29.3333 18.9198 29.12 21.0665 28.7467 22.4398C28.4133 23.6398 27.64 24.4132 26.44 24.7465C25.8133 24.9198 24.6667 25.0398 22.9067 25.1198C21.1733 25.2132 19.5867 25.2532 18.12 25.2532L16 25.3332C10.4133 25.3332 6.93332 25.1198 5.55999 24.7465C4.35999 24.4132 3.58666 23.6398 3.25332 22.4398C3.07999 21.8132 2.95999 20.9732 2.87999 19.9065C2.78666 18.8398 2.74666 17.9198 2.74666 17.1198L2.66666 15.9998C2.66666 13.0798 2.87999 10.9332 3.25332 9.55984C3.58666 8.35984 4.35999 7.5865 5.55999 7.25317C6.18666 7.07984 7.33332 6.95984 9.09332 6.87984C10.8267 6.7865 12.4133 6.7465 13.88 6.7465L16 6.6665C21.5867 6.6665 25.0667 6.87984 26.44 7.25317C27.64 7.5865 28.4133 8.35984 28.7467 9.55984Z" />
                  </svg>
                </Link>
                <Link
                  to="https://open.spotify.com/show/5nwTsLIyikh9cMZJ3YHvtE?si=2578fa08dace4966"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <path d="M23.8667 14.5332C19.6 11.9998 12.4667 11.7332 8.39999 12.9998C7.73332 13.1998 7.06666 12.7998 6.86666 12.1998C6.66666 11.5332 7.06666 10.8665 7.66666 10.6665C12.4 9.2665 20.2 9.53317 25.1333 12.4665C25.7333 12.7998 25.9333 13.5998 25.6 14.1998C25.2667 14.6665 24.4667 14.8665 23.8667 14.5332ZM23.7333 18.2665C23.4 18.7332 22.8 18.9332 22.3333 18.5998C18.7333 16.3998 13.2667 15.7332 9.06666 17.0665C8.53332 17.1998 7.93332 16.9332 7.79999 16.3998C7.66666 15.8665 7.93332 15.2665 8.46666 15.1332C13.3333 13.6665 19.3333 14.3998 23.4667 16.9332C23.8667 17.1332 24.0667 17.7998 23.7333 18.2665ZM22.1333 21.9332C21.8667 22.3332 21.4 22.4665 21 22.1998C17.8667 20.2665 13.9333 19.8665 9.26666 20.9332C8.79999 21.0665 8.39999 20.7332 8.26666 20.3332C8.13332 19.8665 8.46666 19.4665 8.86666 19.3332C13.9333 18.1998 18.3333 18.6665 21.8 20.7998C22.2667 20.9998 22.3333 21.5332 22.1333 21.9332ZM16 2.6665C14.249 2.6665 12.5152 3.01138 10.8975 3.68144C9.27987 4.35151 7.81001 5.33363 6.5719 6.57175C4.07141 9.07223 2.66666 12.4636 2.66666 15.9998C2.66666 19.5361 4.07141 22.9274 6.5719 25.4279C7.81001 26.666 9.27987 27.6482 10.8975 28.3182C12.5152 28.9883 14.249 29.3332 16 29.3332C19.5362 29.3332 22.9276 27.9284 25.4281 25.4279C27.9286 22.9274 29.3333 19.5361 29.3333 15.9998C29.3333 14.2489 28.9884 12.5151 28.3184 10.8974C27.6483 9.27972 26.6662 7.80986 25.4281 6.57175C24.19 5.33363 22.7201 4.35151 21.1024 3.68144C19.4848 3.01138 17.7509 2.6665 16 2.6665Z" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Podcast;
