import React from "react";
import aImg from "../../images/about/img-2.webp";

const About = (props) => {
  return (
    <section className="tf-about-section reverse section-padding">
      <div className="container">
        <div className="standup-wrapper">
          <div className="tf-about-img reverted">
            <img src={aImg} alt="Pan Judo" />
          </div>
          <div className="tf-about-text">
            <small className="cartoushe">Stand-up</small>
            <h2>
              Program, „Pan Judo”, to przede wszystkim opowieść o wielkiej życiowej pasji,<br/>czyli sztukach walki.
            </h2>
            <p>
              Unikalna perspektywa wyczynowego sportowca, w
              połączeniu z wrodzoną przewrotnością i ogromnym dystansem do rzeczywistości, nie tylko
              bawi, ale również budzi ciekawość. Z kolei najnowsza publikacja o prowokacyjnym tytule
              „Szachy dla debili” - nieco ostrzejsza w środkach wyrazu - jest ironicznym spojrzeniem
              na młodzieńcze fascynacje i obecne problemy, wciąż dorastającego, trzydziestolatka.
              Oba wspomniane tytuły są dostępne na youtube.
              Aktualnie Filip jest w trasie z trzecim solowym programem pt. „OJ EJAJ”.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
