import ThumbnailPlaceholder from "../../images/shows/thumbnailPlaceholder.png";
import CalendarIcon from "../../images/shows/calendar.svg";
import ClockIcon from "../../images/shows/clock.svg";
import LocationIcon from "../../images/shows/location.svg";
import React from "react";
import ReactPixel from "react-facebook-pixel/src/index";

const SingleShow = (attributes) => {
  const handleBuyTicket = () => {
    ReactPixel.track("addToCart");
    window.open(attributes.buyTicketLink, "_blank");
  };
  const url = attributes?.thumbnail?.data?.attributes?.url;

  return (
    <div className="single-show">
      <a className="left" href={`/shows/${attributes.id}`}>
        <h3 className="show-title small">{attributes.title}</h3>
        <img
          src={
            attributes.isFromKupBilecik
              ? attributes.thumbnailUrl
              : url
              ? process.env.REACT_APP_STRAPI_URL + url
              : ThumbnailPlaceholder
          }
          alt="Show thumbnail"
        />
        <div className="show-description">
          <h3 className="show-title">{attributes.title}</h3>
          <div className="show-details">
            <div className="single-detail">
              <img src={CalendarIcon} alt="" />
              <p>{attributes.date}</p>
            </div>
            <div className="single-detail">
              <img src={ClockIcon} alt="" />
              <p>{attributes.time.slice(0, 5)}</p>
            </div>
            <br />
            <div className="single-detail">
              <img src={LocationIcon} alt="" />
              <p><span style={{textTransform:'uppercase', fontWeight: 'bolder' }}>{attributes.city ||""}</span> {attributes.address}</p>
            </div>
          </div>
        </div>
      </a>
      <div className="right">
        <button onClick={handleBuyTicket} className="btn-alt">
          Kup bilet
        </button>
      </div>
    </div>
  );
};
export default SingleShow;
