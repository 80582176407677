import React from "react";
import { NavLink } from "react-router-dom";
import himg from "../../images/slider/head-min1.webp";
import { Link } from "react-scroll";

const Hero = () => {
  return (
    <section className="tp-hero-section-1">
      <div className="container">
        <div className="row">
          <div className="col col-xs-7 col-lg-7">
            <div className="tp-hero-section-text">
              <small className="cartoushe">Filip Puzyr</small>
              <div className="tp-hero-title">
                <h2>Żarty<br/>które śmieszą</h2>
              </div>
              <div className="btns">
                <Link
                  activeClass="active"
                  to="youtube"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-95}
                  className="theme-btn"
                >
                  Zobacz mój występ
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-vec">
        <div className="right-img">
          <img src={himg} alt="Filip Puzyr" />
        </div>
      </div>
      <div className="social-link">
        <ul>
          <li>
            <NavLink to="https://www.facebook.com/fpuzyr" target="_blank">
              Facebook
            </NavLink>
          </li>
          <li>
            <NavLink to="https://www.instagram.com/filippuzyr/" target="_blank">
              Instagram
            </NavLink>
          </li>
          <li>
            <NavLink to="https://www.youtube.com/@FilipPuzyr" target="_blank">
              YouTube
            </NavLink>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Hero;
