import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import SingleShowDetails from "../SingleShow/SingleShowDetails";

const ShowsDetailsPage = () => {
  return (
    <Fragment>
      <Navbar />
      <SingleShowDetails />
      <Footer />
    </Fragment>
  );
};
export default ShowsDetailsPage;
