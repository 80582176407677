import React from "react";
import { Link } from "react-scroll";

import BackgroundImage from "../../images/partnership/background.webp";
import BackgroundImage2 from "../../images/partnership/background2.webp";

const About = () => {
  return (
    <section id="partnership" className="partnership-section section-padding">
      <img className="background-image" src={BackgroundImage} alt="" />
      <div className="container content-wrapper">
        <div className="title-wrapper">
          <small className="cartoushe">Współpraca</small>
          <h2>
            Pragniesz zorganizować występ stand-upowy w swoim lokalu? A może
            szukasz profesjonalnego stand-upera na imprezę firmową lub inną
            szczególną okazję?
          </h2>
        </div>
        <div className="text-wrapper">
          <p>
            Filip Puzyr to idealny wybór dla wszystkich, którzy cenią śmiałą,
            lecz nie pozbawioną subtelności komedię. Filip posiada bogate
            doświadczenia improwizatorskie, dzięki któremu błyskawicznie
            nawiązuje kontakt z publicznością, niezależnie od okoliczności. Z
            kolei jego błyskotliwe poczucie humoru oraz bezkompromisowe,
            nieoczywiste żarty, na długo zapadają widzom w pamięć.
          </p>
          <p>
            Przez lata działalności na scenie Filip występował we wszelkich
            możliwych warunkach. Puby, kawiarnie, teatry, plenery, czy nawet
            dach Cinquecento - żadne okoliczności nie są mu obce.
          </p>
          <p>
            Rozśmieszał on zarówno publiczność w zadymionych piwnicach, jak i na
            największych scenach w Polsce (Opera Leśna w Sopocie, Atlas Arena w
            Łodzi, czy Netto Arena w Szczecinie).
          </p>
          <ul>
            <li>
              Współpracował on m.in. z Amazon Prime Video przy polskiej edycji
              popularnego programu „LOL – kto się śmieje ostatni?”
            </li>
            <li>Współtworzył świetnie przyjęty sketch-show „107 OGARNiA”. </li>
            <li>
              Sprawował również funkcję scenarzysty oraz opiekuna artystycznego
              w „Roaście Pudziana”, dbając o przygotowanie gościnnie
              występujących celebrytów.
            </li>
          </ul>
          <p>
            Jeśli więc poszukujesz do współpracy zaprawionego w boju komika,
            zapraszamy do kontaktu.
          </p>
        </div>
        <Link to="contact" smooth={true} duration={500} offset={-100}>
          <button className="theme-btn">Napisz do mnie</button>
        </Link>
      </div>
      <img src={BackgroundImage2} alt="" className="background-image-2" />
    </section>
  );
};

export default About;
