import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import BlogPageContent from '../../components/BlogSection/BlogPageContent'

const HomePage = () => {
  return (
    <Fragment>
      <Navbar />
      <BlogPageContent />
      <Footer />
    </Fragment>
  );
};
export default HomePage;
