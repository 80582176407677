import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { useParams } from "react-router-dom";
import getPost from "../../api/action.getPost";
import moment from "moment";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import ReactMarkdown from "react-markdown";
import logError from "../../helpers/logError";
import BlogPlaceholder from "../../images/blog/placeholder.png";

const BlogSinglePage = () => {
  const { id } = useParams();
  const [post, setPost] = useState();

  useEffect(() => {
    (async () => {
      if (id) {
        try {
          const { data } = await getPost(id);
          setPost(data);
        } catch (err) {
          logError(err);
        }
      }
    })();
  }, [id]);

  const img = post?.attributes?.thumbnail?.data?.attributes?.formats?.thumbnail?.url;
  console.log(img)
  return (
    <Fragment>
      <Navbar />
      <div className="blogSinglePage">
        <h1 className="blogSinglePage__title">{post?.attributes?.title}</h1>
        <div className="blogSinglePage__subtitle">
          {post?.attributes?.author}
          <div className="blogSinglePage__redline" />
          {moment(post?.attributes?.createdAt).format("L")}
        </div>
        <img
          alt=""
          className="blogSinglePage__img"
          src={img ? process.env.REACT_APP_STRAPI_URL + img : BlogPlaceholder}
        />
        <ReactMarkdown
          transformImageUri={(uri) =>
            `${process.env.REACT_APP_STRAPI_URL}${uri}`
          }
        >
          {post?.attributes?.body}
        </ReactMarkdown>
        <div className="blogSinglePage__footer">
          <div className="blogSinglePage__share">Udostępnij:</div>
          <ul className="blogSinglePage__links">
            <li>
              <FacebookShareButton
                className="blogSinglePage__links--item"
                url={window.location.href}
              >
                Facebook
              </FacebookShareButton>
            </li>
            <li>
              <TwitterShareButton
                className="blogSinglePage__links--item"
                url={window.location.href}
              >
                Twitter
              </TwitterShareButton>
            </li>
            <li>
              <LinkedinShareButton
                className="blogSinglePage__links--item"
                url={window.location.href}
              >
                Linkedin
              </LinkedinShareButton>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};
export default BlogSinglePage;
