import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import getPosts from "../../api/action.getPosts";
import logError from "../../helpers/logError";
import BlogPlaceholder from "../../images/blog/placeholder.png";
import sortBlogPosts from "../../helpers/sortBlogPosts";

const BlogPageContent = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const rawPosts = await getPosts();
        if (!rawPosts) {
          throw "no posts";
        }
        setBlogs(sortBlogPosts(rawPosts.data));
      } catch (err) {
        logError(err);
      }
    })();
  }, []);

  return (
    <section className="tp-blog-section page section-padding">
      <div className="container">
        <div className="tp-section-title">
          <span>Blog</span>
          <h2>
            Czasami godzina gadania śmiesznych rzeczy do mikrofonu to zwyczajnie
            za mało.
          </h2>
        </div>
        <div className="tp-blog-items">
          <div className="row">
            {blogs.map(({ attributes, id }, index) => {
              const img =
                attributes?.thumbnail?.data?.attributes?.formats?.thumbnail
                  ?.url;
              return (
                <a
                  href={`/blog/${id}`}
                  className="col col-lg-4 col-md-6 col-12"
                  key={index}
                >
                  <div className="tp-blog-item">
                    <div className="tp-blog-img">
                      <img
                        width="200"
                        height="200"
                        src={
                          img
                            ? process.env.REACT_APP_STRAPI_URL + img
                            : BlogPlaceholder
                        }
                        alt=""
                      />
                    </div>
                    <div className="tp-blog-content">
                      <ul>
                        <li>{attributes.date}</li>
                      </ul>
                      <h2>{attributes.title}</h2>
                      <p>{attributes.description}</p>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogPageContent;
