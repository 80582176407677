import React, { useEffect } from "react";
import Scroll from "react-scroll";
import { Link as NavLink } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import Logo from "../../images/logo.svg";
import { links } from "../../constans";
import { useLocation } from "react-router-dom";
const Header = (props) => {
  const { pathname } = useLocation();
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (window.location.hash) {
      if (window.innerWidth > 992) {
        const el = document?.querySelector(window.location.hash);
        Scroll.animateScroll.scrollTo(el.offsetTop + window.innerHeight / 1.4);
      } else {
        document?.querySelector(window.location.hash).scrollIntoView();
      }
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes("/shows")) {
      const shows = document.querySelectorAll("#shows");
      shows.forEach((element) => {
        element.classList.add("active");
      });
    } else if (window.location.href.includes("/blog")) {
      const blogs = document.querySelectorAll("#blog");
      blogs.forEach((element) => {
        element.classList.add("active");
      });
    } else {
      const shows = document.querySelectorAll("#shows");
      shows.forEach((element) => {
        element.classList.remove("active");
      });
      const blogs = document.querySelectorAll("#blog");
      blogs.forEach((element) => {
        element.classList.remove("active");
      });
    }
  }, [window.location.href]);

  return (
    <header id="header" className={props.topbarNone}>
      <div className={`tp-site-header active`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="navbar-wrapper">
              <div className="col-lg-3 col-0 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="logo-wrapper">
                <div className="navbar-header">
                  <NavLink
                    onClick={ClickHandler}
                    className="navbar-brand"
                    to="/"
                  >
                    <img src={Logo} alt="logo" />
                  </NavLink>
                </div>
              </div>
              <div className="navigation-wrapper">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav">
                    {links(pathname).map(({ label, link: to, type, id }) => {
                      const linkProperties = {
                        to,
                        duration: 500,
                        offset: -100,
                      };

                      return (
                        <li key={label}>
                          {type === "scroll" ? (
                            <Scroll.Link
                              id={id}
                              smooth={true}
                              activeClass="active"
                              spy={true}
                              {...linkProperties}
                            >
                              {label}
                            </Scroll.Link>
                          ) : (
                            <NavLink
                              onClick={scrollTop}
                              {...linkProperties}
                              id={id}
                            >
                              {label}
                            </NavLink>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
