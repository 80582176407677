const sortBlogPosts = (data) => {
  const withIndex = [];
  const withoutIndex = [];

  data.forEach((item) => {
    if (item.attributes.index !== null) withIndex.push(item);
    else withoutIndex.push(item);
  });

  withIndex.sort((a, b) => a.attributes.index - b.attributes.index);

  withoutIndex.sort(
    (a, b) => new Date(b.attributes.date) - new Date(a.attributes.date),
  );

  return [...withIndex, ...withoutIndex];
};

export default sortBlogPosts;
