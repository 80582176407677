const sortEvents = (events) =>
  events.sort((event1, event2) => {
    const date1 = new Date(
      event1.attributes.date + "T" + event1.attributes.time,
    );
    const date2 = new Date(
      event2.attributes.date + "T" + event2.attributes.time,
    );

    if (date1 < date2) return -1;
    if (date1 > date2) return 1;
    return 0;
  });

export default sortEvents;
