import React, { useEffect, useState } from "react";

import BackgroundImage from "../../images/shows/background-reverted.webp";

import getShows from "../../api/action.getShows";
import getShowsKupBilecik from "../../api/action.getShowsKupBilecik";
import SingleShow from "../SingleShow/SingleShow";
import sortEvents from "../../helpers/sortEvents";
import logError from "../../helpers/logError";

const About = () => {
  const [shows, setShows] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const shows = await getShows();
        const showsKupBilecik = await getShowsKupBilecik();

          setShows(
              sortEvents(
                  [
                      ...shows.data,
                      ...showsKupBilecik.data.map((props) => ({
                          ...props,
                          isFromKupBilecik: true,
                      })),
                  ].filter(
                      (e) => toDate(new Date(Date.parse(e.attributes.date))) >= toDate(new Date())
                  )
              )
          );

          function toDate(dateTime) {
              return new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());
          }

      } catch (err) {
        logError(err);
      }
    })();
  }, []);

  return (
    <section className="shows-section page section-padding">
      <img className="background-image" src={BackgroundImage} alt="" />
      <div className="container content-wrapper">
        <div className="title-wrapper">
          <small className="cartoushe">Nadchodzące występy</small>
          <h2>Wspaniały stand-up, nie zapomnisz go nigdy.</h2>
        </div>
        <div className="shows-wrapper">
          {shows.map(({ id, attributes, isFromKupBilecik }) => (
            <SingleShow
              id={id}
              key={attributes.id}
              {...attributes}
              isFromKupBilecik={isFromKupBilecik}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
