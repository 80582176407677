import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import BackgroundImage from "../../images/shows/background1.webp";

import TicketBuyHero from "../TicketButHero/TicketBuyHero";
import getShows from "../../api/action.getShows";
import getShowsKupBilecik from "../../api/action.getShowsKupBilecik";
import sortEvents from "../../helpers/sortEvents";
import SingleShow from "../SingleShow/SingleShow";
import logError from "../../helpers/logError";

const About = () => {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [shows, setShows] = useState([]);

  useEffect(() => {
    //todo avoid duplicate codes
    (async () => {
      try {
        const shows = await getShows();
        const showsKupBilecik = await getShowsKupBilecik();

        setShows(
          sortEvents(
            [
              ...shows.data,
              ...showsKupBilecik.data.map((props) => ({
                ...props,
                isFromKupBilecik: true,
              })),
            ].filter(
              (e) => new Date(Date.parse(e.attributes.date)) > new Date()
            )
          )
        );
      } catch (err) {
        logError(err);
      }
    })();
  }, []);

  return (
    <section className="shows-section section-padding">
      <img className="background-image" src={BackgroundImage} alt="" />
      <div className="container content-wrapper">
        <div className="title-wrapper">
          <small className="cartoushe">Nadchodzące występy</small>
          <h2>Gorące występy w Twojej okolicy</h2>
        </div>
        <div className="shows-wrapper">
          {shows.slice(0, 3).map(({ id, attributes, isFromKupBilecik }) => {
            return (
              <SingleShow
                id={id}
                key={attributes.id}
                {...attributes}
                isFromKupBilecik={isFromKupBilecik}
              />
            );
          })}
        </div>
        <Link onClick={scrollTop} to="/shows">
          <button className="theme-btn">Zobacz wszystkie</button>
        </Link>
      </div>
    </section>
  );
};

export default About;
