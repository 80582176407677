const sortPodcasts = (data) => {
  const sortedData = data;

  sortedData.sort((a, b) => {
    const indexA = a.attributes.index;
    const indexB = b.attributes.index;

    if (indexA === null && indexB === null) return 0;
    else if (indexA === null) return 1;
    else if (indexB === null) return -1;

    return indexA - indexB;
  });

  return sortedData;
};

export default sortPodcasts;
