export const links = (pathname) => {
  const isHome = pathname === "/";

  return [
    {
      label: "Home",
      link: isHome ? "home" : "/",
      type: isHome ? "scroll" : "page",
    },
    {
      label: "Współpraca",
      link: !isHome ? "/#partnership" : "partnership",
      type: !isHome ? "page" : "scroll",
    },
    {
      label: "O mnie",
      link: !isHome ? "/#about" : "about",
      type: !isHome ? "page" : "scroll",
    },
    {
      label: "Występy",
      link: "/shows",
      type: "page",
      id: "shows",
    },
    {
      label: "YouTube",
      link: !isHome ? "/#youtube" : "youtube",
      type: !isHome ? "page" : "scroll",
    },
    {
      label: "Podcast",
      link: !isHome ? "/#podcast" : "podcast",
      type: !isHome ? "page" : "scroll",
    },
    {
      label: "Blog",
      link: "/blog",
      type: "page",
      id: "blog",
    },
    {
      label: "Newsletter",
      link: !isHome ? "/#newsletter" : "newsletter",
      type: !isHome ? "page" : "scroll",
    },
    {
      label: "Kontakt",
      link: !isHome ? "/#contact" : "contact",
      type: !isHome ? "page" : "scroll",
    },
  ];
};
