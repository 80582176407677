import { useState } from "react";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from "../HomePage/HomePage";
import ShowsPage from "../../components/Pages/ShowsPage";
import BlogPage from "../../components/Pages/BlogPage";
import BlogSinglePage from "../../components/Pages/BlogSinglePage";
import ShowsDetailsPage from "../../components/Pages/ShowsDetailsPage";

export const MobileMenuContext = React.createContext(null);

const AllRoute = () => {
  const [menuActive, setMenuState] = useState(false);

  return (
    <div className="App">
      <BrowserRouter>
        <MobileMenuContext.Provider
          value={{ menuActive: menuActive, setMenuState: setMenuState }}
        >
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route path="shows" element={<ShowsPage />} />
            <Route path="blog" element={<BlogPage />} />
            <Route path="blog/:id" element={<BlogSinglePage />} />
            <Route path="shows/:id" element={<ShowsDetailsPage />} />
          </Routes>
        </MobileMenuContext.Provider>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
