import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Link } from "react-scroll";
import "./style.css";
import { links } from "../../constans";
import { useWindowSize } from "usehooks-ts";
import { Link as NavLink, useLocation } from "react-router-dom";
import ListItem from "@material-ui/core/List";
import { MobileMenuContext } from "../../main-component/router";

const MobileMenu = () => {
  const contextValue = useContext(MobileMenuContext);
  const { menuActive, setMenuState } = contextValue;

  const { width } = useWindowSize();
  const { pathname } = useLocation();
  const ClickHandler = () => {
    width > 996 && window.scrollTo(10, 0);
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const menuRef = useRef(null);

  // Function to handle clicks outside the menu
  const handleClickOutside = useCallback(
    (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuState(false); // Close the menu when clicking outside
      }
    },
    [setMenuState],
  );

  // Effect hook to add and remove the click event listener
  useEffect(() => {
    if (menuActive) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuActive, handleClickOutside]);

  // Function to handle the menu button click
  const handleMenuButtonClick = () => {
    setMenuState(!menuActive); // Toggle the menu state (open/close)
  };

  return (
    <div ref={menuRef}>
      <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
        <div className="menu-close">
          <div className="clox showmenu" onClick={handleMenuButtonClick}>
            <i className="ti-close"></i>
          </div>
        </div>

        <ul className="responsivemenu">
          {links(pathname).map(({ label, link, type, id }, index) => {
            const linkProperties = {
              to: link,
              duration: 500,
              onClick: ClickHandler,
            };
            return (
              <ListItem key={index}>
                {type === "scroll" ? (
                  <Link smooth={true} spy={true} {...linkProperties}>{label}</Link>
                ) : (
                  <NavLink {...linkProperties} id={id} onClick={scrollTop}>
                    {label}
                  </NavLink>
                )}
              </ListItem>
            );
          })}
        </ul>
      </div>

      <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
        <button type="button" className="navbar-toggler open-btn">
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>
    </div>
  );
};

export default MobileMenu;
