import CalendarIcon from "../../images/shows/calendar.svg";
import ClockIcon from "../../images/shows/clock.svg";
import LocationIcon from "../../images/shows/location.svg";
import React, { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel/src/index";
import BakcgroundImage from "../../images/shows/background-details.webp";
import { useParams } from "react-router-dom";
import getShow from "../../api/action.getShow";
import logError from "../../helpers/logError";
import getShowKupBilecik from "../../api/action.getShowKupBilecik";
import { useWindowSize } from "usehooks-ts";
import removeBuyTicketLabel from "../../helpers/removeBuyTicketLabel";
import { useNavigate } from "react-router-dom";

const SingleShowDetails = () => {
  ReactPixel.track("event visited");

  const { id } = useParams();
  const { width } = useWindowSize();

  const [details, setDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      let showFound = false;
      try {
        const {
          data: { attributes },
        } = await getShow(id);
        setDetails(attributes);
        showFound = true;
      } catch (err) {
        logError(err);
      }
      try {
        const {
          data: { attributes },
        } = await getShowKupBilecik(id);
        setDetails(attributes);
        showFound = true;
      } catch (err) {
        logError(err);
      }
      if (!showFound) {
        navigate("/shows");
      }
    })();
  }, []);

  if (!details) {
    return null;
  }

  return (
    <section className="shows-details">
      <img src={BakcgroundImage} className="background-image" alt="" />
      <div className="container">
        <div className="single-show-details">
          <div className="title-wrapper">
            <small className="cartoushe grey">Występ</small>
          </div>
          <div className="info">
            <div className={`left ${width <= 991 ? "w-100" : "w-75"}`}>
              <h3 className="title">{details.title}</h3>
              <div className="lower">
                <div className="show-details">
                  <div className="single-detail">
                    <img src={CalendarIcon} alt="" />
                    <p>{details.date}</p>
                  </div>
                  <div className="single-detail">
                    <img src={ClockIcon} alt="" />
                    <p>{details.time.slice(0, 5)}</p>
                  </div>
                  <div className="single-detail">
                    <img src={LocationIcon} alt="" />
                    <p>
                      {details.city} {details.address}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`right ${width <= 991 ? "w-100" : "w-25"}`}
              style={{ textAlign: "left" }}
            >
              <a href={details.buyTicketLink}>
                <button className="btn-alt btn-show-details">Kup bilet</button>
              </a>
            </div>
          </div>
          <div className="description">
            {details.description && (
              <p
                dangerouslySetInnerHTML={{
                  __html: removeBuyTicketLabel(
                    details.description,
                    " Bilety do nabycia na:"
                  ),
                }}
              ></p>
            )}
          </div>
          <a href="/shows" className="go-back-btn theme-btn">
            Wróć do listy występów
          </a>
        </div>
      </div>
    </section>
  );
};
export default SingleShowDetails;
