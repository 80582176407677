import { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BackgroundImage from "../../images/blog/background.webp";
import { Link } from "react-router-dom";
import BlogPlaceholder from "../../images/blog/placeholder.png";

import getPosts from "../../api/action.getPosts";
import logError from "../../helpers/logError";
import sortBlogPosts from "../../helpers/sortBlogPosts";

const BlogSection = () => {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const rawPosts = await getPosts();
        if (rawPosts.error) {
          throw "No posts";
        }
        setPosts(sortBlogPosts(rawPosts.data));
      } catch (err) {
        logError(err);
      }
    })();
  }, []);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // Slicing array depending on screen width

  const [sliceNumber, setSliceNumber] = useState(3);

  useEffect(() => {
    if (windowSize <= 991 && windowSize >= 767) {
      setSliceNumber(4);
    } else {
      setSliceNumber(3);
    }
  }, [windowSize]);

  return (
    <section className="tp-blog-section section-padding" id="blog">
      <img src={BackgroundImage} alt="" className="background-image" />
      <div className="container">
        <div className="tp-section-title">
          <span>Blog</span>
          <h2>
            Obserwacje, żarty oraz luźne przemyślenia, które
            warto rozwinąć nieco bardziej niż na scenie.
          </h2>
        </div>
        <div className="tp-blog-items">
          <div className="row">
            {posts.slice(0, sliceNumber).map((post, index) => {
              const img =
                post?.attributes?.thumbnail?.data?.attributes?.formats
                  ?.thumbnail?.url;
              return (
                <a
                  href={`/blog/${post.id}`}
                  key={index}
                  className="col col-lg-4 col-md-6 col-12"
                >
                  <div className="tp-blog-item">
                    <div className="tp-blog-img">
                      <img
                        src={
                          img
                            ? process.env.REACT_APP_STRAPI_URL + img
                            : BlogPlaceholder
                        }
                        alt=""
                      />
                    </div>
                    <div className="tp-blog-content">
                      <ul>
                        <li>{post.attributes.date}</li>
                      </ul>
                      <h2>{post.attributes.title}</h2>
                      <p>{post.attributes.description}</p>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
          <div className={`sec-title-btn text-center mt-3`}>
            <Link onClick={scrollTop} to="/blog">
              <span>
                <button className="theme-btn">Zobacz wszystkie</button>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
