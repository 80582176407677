import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

import BackgroundImage from "../../images/youtube/background.webp";

import getVideos from "../../api/action.getVideos";
import logError from "../../helpers/logError";
import sortVideos from "../../helpers/sortVideos";

const Youtube = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const videos = await getVideos();
        if (!videos.data.length) {
          throw "No videos";
        }
        setVideos(sortVideos(videos.data));
      } catch (err) {
        logError(err);
      }
    })();
  }, []);

  return (
    <section className="youtube-section section-padding" id="youtube">
      <img className="background-image" src={BackgroundImage} alt="" />
      <div className="container content-wrapper">
        <div className="title-wrapper">
          <small className="cartoushe">YouTube</small>
          <h2>
            Na kanale YouTube’owym Filipa znajdziesz nie tylko jego pełne
            programy stand-upowe, ale również ekskluzywne nagrania oraz zupełnie
            inne formaty.
          </h2>
        </div>
        {videos && (
          <>
            <div className="react-big-player">
              <ReactPlayer
                url={
                  videos.find((video) => {
                    return video.attributes.index === 1;
                  })?.attributes?.url
                }
                controls
                width="100%"
                height="100%"
              />
            </div>
            <div className="react-videos-section">
              <div className="react-small-player">
                <ReactPlayer
                  url={
                    videos.find((video) => {
                      return video.attributes.index === 2;
                    })?.attributes?.url
                  }
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
              <div className="react-small-player">
                <ReactPlayer
                  url={
                    videos.find((video) => {
                      return video.attributes.index === 3;
                    })?.attributes?.url
                  }
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
              <div className="react-small-player">
                <ReactPlayer
                  url={
                    videos.find((video) => {
                      return video.attributes.index === 4;
                    })?.attributes?.url
                  }
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </>
        )}
        <Link
          to="http://youtube.com/channel/UC76NIMtjPXMgnISbY_oV-tQ?sub_confirmation=1"
          target="_blank"
        >
          <button className="theme-btn subscribe-btn">Subskrybuj</button>
        </Link>
      </div>
    </section>
  );
};

export default Youtube;
