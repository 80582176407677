import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '../src/main-component/App/App';
import reportWebVitals from './reportWebVitals';
import './css/font-awesome.min.css';
import './css/themify-icons.css';
import './css/flaticon.css';
import './css/animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './sass/style.scss';
import ReactPixel from 'react-facebook-pixel/src/index';

const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init(process.env.REACT_APP_META_PIXEL_ID, {}, options);
ReactPixel.pageView();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
