import logError from "../helpers/logError";

const newsletterAdd = (email, callback, errorCallback) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = "";

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    `${process.env.REACT_APP_STRAPI_URL}/api/newsletter?email=${email}`,
    requestOptions,
  )
    .then(({ status }) => {
      if (status === 200 || status === 202 || status === 409) {
        callback();
      } else {
        errorCallback();
      }
    })
    .then((result) => result)
    .catch((error) => {
      logError(error);
      errorCallback();
    });
};

export default newsletterAdd;
