import React from "react";
import aImg from "../../images/about/img-1.webp";

const About = () => {
  return (
    <section className="tf-about-section section-padding" id="about">
      <div className="container">
        <div className="tf-about-wrap">
          <div className="about-content-wrapper">
            <div className="tf-about-img">
              <img src={aImg} alt="" />
            </div>
            <div className="tf-about-text">
              <small>O mnie</small>
              <h2>Kim jest Filip Puzyr?</h2>
              <p>
                Z gdańską sceną komediową związał się przeszło dekadę temu. Jego
                znakiem rozpoznawczym są wszechstronność oraz szerokie
                zainteresowania, co znajduje swoje odzwierciedlenie we
                wszystkich sferach jego artystycznej działalności.
              </p>
              <p>
                Pierwsze komediowe kroki stawiał jako uzdolniony improwizator.
                Za sprawą projektu „One Puzyr Show” stał się też jedną z
                niewielu osób w Polsce, które wyspecjalizowały się w
                improwizacjach solowych. Doświadczenie Filipa ze świata impro z
                pewnością przełożyło się na jego styl uprawiania stand-upu,
                charakteryzujący się dużą swobodą prowadzenia narracji oraz
                świetnym kontaktem z widownią.
              </p>
              <p>
                Tematycznie Filip porusza się pomiędzy wnikliwą obserwacją
                codzienności, a radosnym, bezpretensjonalnym absurdem. To
                nieoczywiste połączenie szybko podbiło serca fanów stand-upu w
                całym kraju, o czym świadczy fakt, że nagrania na YouTube’owym
                kanale Filipa obejrzało już przeszło 5 milionów osób.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
