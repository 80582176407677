import React, { useState } from "react";

import BackgroundImage from "../../images/newsletter/background.webp";
import logError from "../../helpers/logError";
import checkValidFormat from "../../helpers/checkValidFormat";
import newsletterAdd from "../../api/action.newsletterAdd";
import ReactPixel from "react-facebook-pixel/src/index";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isSignedInError, setIsSignedInError] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSignedInError(false);
    setIsSignedIn(false);

    if (!email || !checkValidFormat(email)) {
      setIsValidEmail(false);
      return;
    }

    setIsValidEmail(true);

    try {
      newsletterAdd(
        email,
        () => {
          setIsSignedIn(true);
          ReactPixel.track("Lead");
        },
        () => {
          setIsSignedInError(true);
        }
      );
    } catch (error) {
      logError(error);
    }
  };

  return (
    <section
      className="tf-about-section reverse section-padding"
      id="newsletter"
    >
      <div className="container">
        <div className="tf-about-wrap">
          <div className="row align-items-center standup-wrapper">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="tf-about-img reverted">
                <img src={BackgroundImage} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="tf-about-text">
                <small>Newsletter</small>
                <h2>
                  Doscrollowałeś aż tutaj? Należy Ci się nagroda! Co powiesz na
                  newsletter?
                </h2>
                <p>
                  “Nie, dzięki”? W porządku, ale to naprawdę fajny newsletter.
                  Dzięki niemu poznasz między innymi odpowiedź na wiekopomne
                  pytanie: “Kiedy Poznań/Sopot/Dzierżoniów/Twoje Miasto”.
                </p>
                <p>
                  Otrzymasz również dostęp do wielu innych - nie bójmy się tego
                  słowa - benefitów. Jakich? Tego już się dowiesz z newslettera.
                  Spoilerom mówimy “nie”.
                </p>
              </div>
              <form
                className="newsletter-input-wrapper"
                onSubmit={handleSubmit}
              >
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button>Dołącz</button>
              </form>
              {!isValidEmail && (
                <p style={{ marginTop: "10px", color: "#e34343" }}>
                  Podaj poprawny adres email
                </p>
              )}

              {isSignedIn && (
                <p style={{ marginTop: "10px", color: "#f5f5f5" }}>
                  Zapisano do newslettera
                </p>
              )}
              {isSignedInError && (
                <p style={{ marginTop: "10px", color: "#e34343" }}>
                  Przepraszamy, nie udało się zapisać do newslettera
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
