const sortVideos = (arr) => {
  return arr.sort((a, b) => {
    if (a.attributes.index === null && b.attributes.index === null) return 0;
    if (a.attributes.index === null) return 1;
    if (b.attributes.index === null) return -1;
    return a.attributes.index - b.attributes.index;
  });
};

export default sortVideos;
