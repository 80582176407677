import React, { Fragment } from "react";
import About from "../../components/about/about";
import Hero from "../../components/hero/hero";
import Navbar from "../../components/Navbar/Navbar";
import { Element } from "react-scroll";
import BlogSection from "../../components/BlogSection/BlogSection";
import Footer from "../../components/footer/Footer";
import StandUp from "../../components/about/standUp";
import Shows from "../../components/shows/Shows";
import Partnership from "../../components/partnership/Partnership";
import Youtube from "../../components/youtube/Youtube";
import Podcast from "../../components/podcast/podcast";
import Newsletter from "../../components/newsletter/newsletter";
import Contact from "../../components/Contact/Contact";

const HomePage = () => {
  return (
    <Fragment>
      <Navbar />
      <Element name="home">
        <Hero />
      </Element>
      <Element name="shows">
        <Shows />
      </Element>
      <Element name="partnership">
        <Partnership />
      </Element>
      <Element name="about">
        <About />
      </Element>
      <Element name="standUp">
        <StandUp />
      </Element>
      <Element name="youtube">
        <Youtube />
      </Element>
      <Element name="podcast">
        <Podcast />
      </Element>
      <Element name="blog">
        <BlogSection />
      </Element>
      <Element name="newsletter">
        <Newsletter />
      </Element>
      <Element name="contact">
        <Contact />
      </Element>
      <Footer />
    </Fragment>
  );
};
export default HomePage;
